import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useApi, components, ducks} from '@arborian/narrf';

import {useGlobals} from 'it/components/Globals';
import useResources from 'it/dataSource/resources';
import {TextField} from 'it/components/Editable';
//import ProviderName from './ProviderName';
import ProviderProfileCodes from './ProviderProfileCodes';
import ProviderSub from './ProviderSub';
import ProviderImpersonate from './ProviderImpersonate';

const {dt2} = components;

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
    selection: true,
};

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

const useProvider = props =>
    useResources({
        link: 'provider.ProviderCollection',
        prefix: 'provider.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });

const selectSubs = ducks.jsonapi.selectObject('Sub');

const useSubLookup = () => {
    const globals = useGlobals();
    useEffect(() => {
        console.log('fetchglobals');
        globals.fetchAll('sub.SubCollection');
    }, [globals]);

    const subs = useSelector(selectSubs);
    return useMemo(
        () =>
            fp.pipe([
                fp.map(sub => [sub.id, sub.attributes.userinfo.email]),
                fp.fromPairs,
            ])(subs),
        [subs],
    );
};

export default function ProviderTable() {
    const ds = useProvider();
    const api = useApi();
    const subLookup = useSubLookup();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isAdmin = fp.includes('__admin__', userinfo?.scopes);

    const handleRefresh = () => {
        ds.fetch();
    };
    const handleMerge = async (ev, items) => {
        const [primary, ...secondaries] = items;
        if (fp.isEmpty(secondaries)) return;
        console.log('Merge some items', {primary, secondaries});
        const attributes = {
            items: fp.map(
                it => ({type: it.data.type, id: it.data.id}),
                secondaries,
            ),
        };
        await api.fetchJson(fp.get('data.links.merger', primary), {
            method: 'POST',
            json: {data: {type: 'ProviderMerger', attributes}},
        });
        ds.fetch();
    };
    console.log({userinfo, subLookup, isAdmin});

    return (
        <dt2.DataTable
            small
            title='Providers'
            dataSource={ds}
            options={TABLE_OPTIONS}
        >
            <dt2.Column
                title='Name'
                field='attributes.name'
                render={item => (
                    <TextField
                        resource={item.data}
                        field='attributes.name'
                        iconBefore
                    />
                )}
            />
            <dt2.Column
                title='Profile Code(s)'
                field='attributes.amd_profile_codes'
                render={item => (
                    <ProviderProfileCodes
                        editable
                        provider={item.data}
                        onChanged={handleRefresh}
                    />
                )}
            />
            <dt2.Column
                title='User'
                field='relationships.auth_sub.data.id'
                render={item => (
                    <ProviderSub
                        editable
                        provider={item.data}
                        onChanged={handleRefresh}
                    />
                )}
            />
            {isAdmin && (
                <dt2.Column
                    title='Actions'
                    filtering={false}
                    render={item => (
                        <ProviderImpersonate provider={item.data} />
                    )}
                />
            )}
            <dt2.Action
                id='merge-codes'
                onClick={handleMerge}
                icon='merge-call'
            />
            <dt2.Action
                free
                id='charge-refresh'
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
