import React from 'react';
import {useHistory} from 'react-router';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {Button} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

const TOKEN_EXCHANGE = 'urn:ietf:params:oauth:grant-type:token-exchange';
const IDENTITY_TOKEN = 'urn:ietf:params:oauth:token-type:id_token';

async function handleImpersonation(api, history, sub) {
    // Get jwks
    const jwks = await api.fetchJson(api.url_for('oauth.jwks'));
    const kid = jwks.keys[0].kid;
    // Create identity token
    const jwt = await api.fetchJson(api.url_for('oauth.jwt', {kid}), {
        method: 'POST',
        json: {
            claims: {sub: sub.id},
        },
    });
    await history.push('/');
    await api.login({
        grant_type: TOKEN_EXCHANGE,
        client_id: api.client_id,
        subject_token: jwt.jwt,
        subject_token_type: IDENTITY_TOKEN,
    });
    window.location.reload();
}

export default function ProviderImpersonate({provider}) {
    const api = useApi();
    const history = useHistory();
    const sub = useSelector(
        ducks.jsonapi.selectObject([
            'Sub',
            fp.get('relationships.auth_sub.data.id', provider),
        ]),
    );
    if (!sub) return null;
    return (
        <Button onClick={ev => handleImpersonation(api, history, sub)}>
            Impersonate
        </Button>
    );
}
