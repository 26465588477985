import React from 'react';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';

import useResources from 'it/dataSource/resources';

const {dt2} = components;

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
    selection: true,
};

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.code', direction: 'asc'},
    page: {number: 0, size: 20},
};

const SUPPRESSED_LOOKUP = {
    true: 'SUPPRESSED',
    false: '',
};

const useCode = props =>
    useResources({
        link: 'charge.CodeCollection',
        prefix: 'code.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });

export default function CodeTable() {
    const ds = useCode();
    const api = useApi();

    const handleRefresh = () => {
        ds.fetch();
    };

    const handleToggleSuppress = async (ev, rows) => {
        const promises = fp.map(obj => {
            const href = fp.get('data.links.self', obj);
            const oldSuppressed = fp.get('data.attributes.suppressed', obj);
            const data = fp.set(
                'data.attributes.suppressed',
                !oldSuppressed,
                obj,
            );
            return api.fetch(href, {method: 'PUT', json: data});
        }, rows);
        await Promise.all(promises);
        ds.fetch();
    };

    return (
        <dt2.DataTable
            small
            title='CPT Codes'
            dataSource={ds}
            options={TABLE_OPTIONS}
        >
            <dt2.Column title='Code' field='attributes.code' />
            <dt2.Column title='UID' field='attributes.uid' />
            <dt2.Column title='Description' field='attributes.description' />
            <dt2.Column title='RVU' field='attributes.rvu' />
            <dt2.Column
                title='Suppress?'
                field='attributes.suppressed'
                type='boolean'
                lookup={SUPPRESSED_LOOKUP}
            />
            <dt2.Action
                id='code-suppress'
                onClick={handleToggleSuppress}
                icon='toggle_on'
                tooltip='Toggle code suppression'
            />
            <dt2.Action
                free
                id='code-refresh'
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
