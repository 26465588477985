import React from 'react';

import {Paper, makeStyles} from '@material-ui/core';
import BasePage from './BasePage';

const useStyles = makeStyles(theme => ({
    root: {
        '& header': {
            textAlign: 'center',
            padding: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                // width: 400,
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
        },
        '& .MuiPaper-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing(4),
            marginBottom: theme.spacing(5),
        },
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
}));

export default function HomePage() {
    const classes = useStyles();
    return (
        <BasePage title='Home'>
            <header className={classes.root}>
                <Paper>
                    <img src='/img/tcpa.png' alt='TCPA logo' />
                </Paper>
            </header>
        </BasePage>
    );
}
