import React, {useRef, useCallback} from 'react';
import {createBrowserHistory} from 'history';

import {MuiThemeProvider, CssBaseline, IconButton} from '@material-ui/core';
import {Provider as ReduxProvider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {SnackbarProvider} from 'notistack';
import {Cancel} from '@material-ui/icons';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';

import {reducer, ApiProvider} from '@arborian/narrf';

import theme from 'it/theme';
import GlobalsProvider from 'it/components/Globals';
import DialogProvider from 'it/components/Dialogs';
import Router from 'it/components/Router';
import '@fontsource/montserrat';

import './App.css';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const store = configureStore({reducer});
const history = createBrowserHistory();

export default function App() {
    console.log({API_ROOT, env: process.env});
    const notistackRef = useRef();
    const handleDismiss = useCallback(
        key => {
            notistackRef.current.closeSnackbar(key);
        },
        [notistackRef],
    );
    const dismissAction = useCallback(
        key => (
            <IconButton onClick={() => handleDismiss(key)}>
                <Cancel />
            </IconButton>
        ),
        [handleDismiss],
    );
    return (
        <ReduxProvider store={store}>
            <ApiProvider
                ui_home='/'
                ui_login='/login'
                history={history}
                api_root={API_ROOT}
                onError={console.log}
            >
                <CssBaseline />
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                        <SnackbarProvider
                            ref={notistackRef}
                            action={dismissAction}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'top',
                            }}
                        >
                            <GlobalsProvider>
                                <DialogProvider>
                                    <Router history={history} />
                                </DialogProvider>
                            </GlobalsProvider>
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </ApiProvider>
        </ReduxProvider>
    );
}
