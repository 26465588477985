import React from 'react';

import ProviderTable from 'it/components/ProviderTable';
import BasePage from './BasePage';

export default function ProviderPage() {
    return (
        <BasePage title='Providers'>
            <ProviderTable />
        </BasePage>
    );
}
