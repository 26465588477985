import React from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';

import {components} from '@arborian/narrf';

import {url_for} from 'it/routes';
import useResources from 'it/dataSource/resources';
import ChipList from 'it/components/ChipList';
import RVUSummaryHeader from 'it/components/RVUSummaryHeader';

const {dt2} = components;

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.team_name', direction: 'asc'},
    page: {number: 0, size: 20},
};

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
};

function useTeamRVUSummaries(chargeCapture) {
    return useResources({
        href: fp.get(
            'relationships.team_rvu_summaries.links.related',
            chargeCapture,
        ),
        prefix: 'prvu.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
    });
}

function DetailLink({chargeCaptureId, teamId}) {
    const href = url_for('teamChargeDetail', {chargeCaptureId, teamId});
    return (
        <Button color='inherit' component={R.Link} to={href}>
            Details
        </Button>
    );
}

function RVUTotal({value}) {
    if (!fp.isNumber(value)) return <span>0</span>;
    return <span>{value.toFixed(2)}</span>;
}

export default function ProviderRVUTable({chargeCapture}) {
    const ds = useTeamRVUSummaries(chargeCapture);

    return (
        <>
            <RVUSummaryHeader
                chargeCapture={chargeCapture}
                dataSource={ds}
                type='team'
            />
            <dt2.DataTable
                small
                title=''
                dataSource={ds}
                options={TABLE_OPTIONS}
            >
                <dt2.Column title='Team' field='attributes.team_name' />
                <dt2.Column
                    title='Facility name(s)'
                    render={item => (
                        <ChipList
                            items={fp.get(
                                'data.attributes.facility_names',
                                item,
                            )}
                        />
                    )}
                />
                <dt2.Column
                    title='RVUs'
                    field='attributes.rvu_total'
                    render={row => (
                        <RVUTotal
                            value={fp.get('data.attributes.rvu_total', row)}
                        />
                    )}
                />
                <dt2.Column
                    title='Detail'
                    render={row => (
                        <DetailLink
                            chargeCaptureId={chargeCapture.id}
                            teamId={fp.get(
                                'relationships.team.data.id',
                                row.data,
                            )}
                        />
                    )}
                />
                <dt2.Action
                    free
                    id='charge-refresh'
                    onClick={() => ds.fetch()}
                    icon='refresh'
                />
            </dt2.DataTable>
        </>
    );
}
