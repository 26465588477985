import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useApi, components, ducks} from '@arborian/narrf';

import useResources from 'it/dataSource/resources';
import {TextField} from 'it/components/Editable';
import TeamFacilityNames from './TeamFacilityNames';

const {dt2} = components;

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
    selection: true,
};

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

const useTeams = props =>
    useResources({
        link: 'team.TeamCollection',
        prefix: 'team.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });

export default function ProviderTable() {
    const ds = useTeams();
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isAdmin = fp.includes('__admin__', userinfo?.scopes);

    const handleRefresh = () => {
        ds.fetch();
    };
    const handleMerge = async (ev, items) => {
        // always merge into the larger team
        items = fp.sortBy(
            it => -it.data.attributes.facility_names.length,
            items,
        );
        const [primary, ...secondaries] = items;
        if (fp.isEmpty(secondaries)) return;
        console.log('Merge some items', {primary, secondaries});
        const attributes = {
            items: fp.map(
                it => ({type: it.data.type, id: it.data.id}),
                secondaries,
            ),
        };
        await api.fetchJson(fp.get('data.links.merger', primary), {
            method: 'POST',
            json: {data: {type: 'TeamMerger', attributes}},
        });
        ds.fetch();
    };
    console.log({userinfo, isAdmin});

    return (
        <dt2.DataTable
            small
            title='Teams'
            dataSource={ds}
            options={TABLE_OPTIONS}
        >
            <dt2.Column
                title='Name'
                field='attributes.name'
                render={item => (
                    <TextField
                        resource={item.data}
                        field='attributes.name'
                        iconBefore
                    />
                )}
            />
            <dt2.Column
                title='Facility name(s)'
                field='attributes.facility_names'
                render={item => (
                    <TeamFacilityNames
                        editable
                        team={item.data}
                        onChanged={handleRefresh}
                    />
                )}
            />
            <dt2.Action
                id='merge-codes'
                onClick={handleMerge}
                icon='merge-call'
            />
            <dt2.Action
                free
                id='charge-refresh'
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
