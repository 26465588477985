import {format, parseISO, differenceInDays, differenceInYears} from 'date-fns';
import {formatInTimeZone, utcToZonedTime, toDate} from 'date-fns-tz';

export const formatDateOnly = dateTime => {
    let result = 'invalid date';
    try {
        if (typeof dateTime === 'string') {
            dateTime = parseISO(dateTime);
        }
        const dateTimeFakeJS = utcToZonedTime(dateTime, 'UTC');
        result = format(dateTimeFakeJS, 'MM/dd/yyyy');
    } catch (e) {
        // console.error('Error formatting datetime:', dateTime);
    }
    return result;
};

export function formatDateTime(value, timeZone) {
    window.datefns = {
        format,
        parseISO,
        differenceInDays,
        differenceInYears,
        formatInTimeZone,
        utcToZonedTime,
        toDate,
    };
    if (!value) return null;
    const date = value instanceof Date ? value : parseISO(value);
    const formatted = formatInTimeZone(
        date,
        timeZone,
        'yyyy-MM-dd HH:mm (zzz)',
    );
    return formatted;
}

export function formatShortDateTime(value, timeZone) {
    if (!value) return null;
    timeZone = timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const date = value instanceof Date ? value : parseISO(value);
    const date = toDate(value);
    const now = new Date();
    const diffDays = differenceInDays(now, date);
    const diffYears = differenceInYears(now, date);
    const formatted =
        diffDays < 1
            ? formatInTimeZone(date, timeZone, 'hh:mm aa (zzz)')
            : diffYears < 1
            ? formatInTimeZone(date, timeZone, 'MMM d')
            : formatInTimeZone(date, timeZone, 'yyyy-M-dd');
    return formatted;
}
