import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {Button} from '@material-ui/core';

import {useApi} from '@arborian/narrf';
import {url_for, useQuery} from 'it/routes';
import BasePage from './BasePage';

function HandleUserNotFoundError({error}) {
    const guideHref =
        'https://samepagemd.zendesk.com/hc/en-us/articles/4406205516051-Getting-Started-on-SamePageMD';
    return (
        <>
            <h4>User not found</h4>
            <br />
            <p>
                If you've recieved an invitation to join SamePageMD, you must
                first register before logging-in.
            </p>
            <p>
                For more information, please see the{' '}
                <a href={guideHref} rel='noreferrer' target='_blank'>
                    registration guide
                </a>
                .
            </p>
            <br />
            <Button
                variant='contained'
                color='primary'
                href={url_for('register')}
            >
                Click here to register
            </Button>
        </>
    );
}

function CodeLoginError({error, text}) {
    const status = fp.get('response.status', error);
    const login = url_for('login');
    if (status === 409) {
        return (
            <p>
                It looks like that email address is already in use. Please{' '}
                <a href={login}>login</a> instead of attempting to register.
            </p>
        );
    }
    console.log('Login error', error.response);
    return (
        <div>
            <h4>Error registering user.</h4>
            <p>
                Please send the following information to support to help us
                diagnose and fix the issue.
            </p>
            <h4>Debugging information</h4>
            <pre>Error: {JSON.stringify(error, null, 2)}</pre>
            <pre>Status: {status}</pre>
            <pre>Text: {text}</pre>
        </div>
    );
}

export default function CallbackPage() {
    const [init, setInit] = useState();
    const query = useQuery();
    const api = useApi();
    const history = useHistory();
    const [content, setContent] = useState(null);

    useEffect(() => {
        async function main() {
            const code = query.get('code');
            const intent = query.get('intent');
            const error = query.get('error');
            const state = query.get('state') || '{}';
            let next = url_for('home');
            try {
                next = fp.getOr('/', 'next', JSON.parse(state));
                if (next === '/callback') {
                    next = url_for('tickets');
                }
            } catch (e) {
                console.error('Error interpreting state param', state, e);
            }
            if (error) {
                switch (error) {
                    case 'User not found':
                        setContent(<HandleUserNotFoundError error={error} />);
                        return;
                    default:
                        setContent(`Auth error: ${error}!`);
                        return;
                }
            }

            if (intent === 'link') {
                try {
                    let refreshed = await api.tryRefresh();
                    if (refreshed) {
                        await api.codeLink(code);
                        history.push(next);
                    } else {
                        console.error('Failed to refresh');
                        history.push(url_for('home'));
                    }
                } catch (e) {
                    throw e;
                }
            } else {
                try {
                    let tok = await api.codeLogin(code);
                    console.log('Got token response', tok, 'redirect to', next);
                    history.push(next);
                } catch (e) {
                    let text = '';
                    console.error('Error logging in with code', code);
                    if (e.response) {
                        console.log('About to get text', {e});
                        try {
                            text = await e.response.text();
                        } catch (err) {
                            text = 'Unable to get response text';
                        }
                    }
                    setContent(<CodeLoginError error={e} text={text} />);
                }
            }
        }
        if (!init) {
            setInit(true);
            main();
        }
    }, [query, history, api, init, setContent]);
    return <BasePage title='Logging you in...'>{content}</BasePage>;
}
