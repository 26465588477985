import {createTheme as createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: '"Montserrat"',
    },
    palette: {
        primary: {
            main: '#006ED2',
        },
        cf: {
            blue: '#0E45D0',
            green: '#119DA4',
            red: '#DB5461',
            accent: '#DEE5F6',
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                width: undefined,
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
                fontSize: 15,
            },
        },
    },
    constants: {
        drawerWidth: 240,
        sidebarWidth: 400,
    },
});

export default theme;
