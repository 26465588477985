import React from 'react';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {HomeWork, MoneyOff, Home, Payment, Person} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import {url_for} from 'it/routes';

const AUTH_ITEMS = [
    {
        primary: 'Charge Captures',
        secondary: 'Show monthly charge captures and summary data',
        href: url_for('chargeCapture'),
        Icon: Payment,
    },
];

const ADMIN_ITEMS = [
    {
        primary: 'Providers',
        secondary: 'Providers & profile codes',
        href: url_for('provider'),
        Icon: Person,
    },
    {
        primary: 'Teams',
        secondary: 'Teams & facilities',
        href: url_for('team'),
        Icon: HomeWork,
    },
    {
        primary: 'Procedure Codes',
        secondary: 'Suppression List',
        href: url_for('code'),
        Icon: MoneyOff,
    },
];

function MenuItems({items}) {
    return (
        <>
            {fp.map(
                it => (
                    <ListItem
                        key={it.href}
                        button
                        component={R.Link}
                        to={it.href}
                    >
                        <ListItemIcon>
                            <it.Icon />
                        </ListItemIcon>
                        <ListItemText
                            primary={it.primary}
                            secondary={it.secondary}
                        />
                    </ListItem>
                ),
                items,
            )}
        </>
    );
}

export default function NavDrawer({open, onClose}) {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItem>
                {userinfo && <MenuItems items={AUTH_ITEMS} />}
                {fp.includes('__admin__', userinfo?.scopes) && (
                    <MenuItems items={ADMIN_ITEMS} />
                )}
            </List>
        </Drawer>
    );
}
