import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from './BasePage';
import ProviderRVUTable from 'it/components/ProviderRVUTable';

function useChargeCapture(chargeCaptureId) {
    const api = useApi();
    useEffect(() => {
        api.fetchJson(
            api.url_for('charge.ChargeCapture', {
                charge_capture_id: chargeCaptureId,
            }),
        );
    }, [api, chargeCaptureId]);
    return useSelector(
        ducks.jsonapi.selectObject(['ChargeCapture', chargeCaptureId]),
    );
}

export default function ProviderRVUSummaryPage() {
    const {chargeCaptureId} = useParams();
    const chargeCapture = useChargeCapture(chargeCaptureId);
    return (
        <BasePage title='Provider RVU Summaries'>
            <ProviderRVUTable chargeCapture={chargeCapture} />
        </BasePage>
    );
}
