import React from 'react';

import TeamTable from 'it/components/TeamTable';
import BasePage from './BasePage';

export default function TeamPage() {
    return (
        <BasePage title='Teams'>
            <TeamTable />
        </BasePage>
    );
}
