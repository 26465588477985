import React from 'react';
import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import RVUHistogram from 'it/components/RVUHistogram';
import {DateTime} from 'it/components/Date';
import {TextField, DateField} from 'it/components/Editable';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(0, 0, 1, 0),
    },
}));

export default function RVUSummaryHeader({chargeCapture, dataSource, type}) {
    const api = useApi();
    const classes = useStyles();
    const canEdit = fp.includes(
        'Common.WRITE',
        fp.get('meta.allowed_actions', chargeCapture),
    );
    const handleRecalculate = async ev => {
        await api.fetch(chargeCapture.links.recalculation, {method: 'POST'});
        dataSource.fetch();
    };
    return (
        <div className={classes.root}>
            <div>
                <h4>
                    RVUs for{' '}
                    <TextField
                        resource={chargeCapture}
                        field='attributes.title'
                    />
                </h4>
                <p>
                    Charges from{' '}
                    <DateField
                        resource={chargeCapture}
                        field='attributes.start_date_inclusive'
                        onChanged={handleRecalculate}
                    />
                    {' to '}
                    <DateField
                        resource={chargeCapture}
                        field='attributes.end_date_exclusive'
                        onChanged={handleRecalculate}
                    />
                    <br />
                    <small>
                        Charges captured from AdvancedMD at{' '}
                        <DateTime
                            value={fp.get(
                                'attributes.captured_at',
                                chargeCapture,
                            )}
                        />
                    </small>
                </p>
                {canEdit && (
                    <Button variant='outlined' onClick={handleRecalculate}>
                        Recalculate
                    </Button>
                )}
            </div>
            <RVUHistogram chargeCapture={chargeCapture} type={type} />
        </div>
    );
}
