import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import LoginButton from 'it/components/LoginButton';

import BasePage from './BasePage';

const useStyles = makeStyles(theme => ({
    root: {},
    buttonContainer: {
        flex: 1,
        justifyContent: 'center',
    },
}));

export default function LoginPage() {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const history = useHistory();
    const classes = useStyles();
    const [providers, setProviders] = useState();
    const {state} = useLocation();

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
            state,
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            throw e;
            /*
            console.error('Got error, trying direct redirect');
            window.location = authorizeLink;
            */
        }
    }, [api, state, setProviders]);

    useEffect(() => {
        if (userinfo && state && state.next) {
            history.push(state.next);
        }
        if (!providers) {
            fetchProviders();
        }
    }, [history, state, userinfo, providers, fetchProviders]);

    const provider = fp.find(
        p => p.attributes.plugin_id === 'google',
        providers,
    );

    if (!provider) return null;

    return (
        <BasePage title='Sign in'>
            <br />
            <br />
            <div className={classes.buttonContainer}>
                <LoginButton
                    key={provider.id}
                    provider={provider}
                    state={state}
                    intent='login'
                >
                    Sign in with {provider.attributes.label}
                </LoginButton>
            </div>
            <br />
        </BasePage>
    );
}
