import React from 'react';

import CodeTable from 'it/components/CodeTable';
import BasePage from './BasePage';

export default function CodePage() {
    return (
        <BasePage title='CPT Codes'>
            <CodeTable />
        </BasePage>
    );
}
