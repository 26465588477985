import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Chip, IconButton} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {ducks, useApi} from '@arborian/narrf';

import {useDialog} from 'it/components/Dialogs';
import SubSelectDialog from './SubSelectDialog';

export default function ProviderProfileCodes({provider, editable, onChanged}) {
    const api = useApi();
    const sub = useSelector(
        ducks.jsonapi.selectObject([
            'Sub',
            fp.get('relationships.auth_sub.data.id', provider),
        ]),
    );
    const subSelectDialog = useDialog(SubSelectDialog);
    const rel_href = fp.get('relationships.auth_sub.links.self', provider);
    const handleDelete = editable
        ? async () => {
              await api.fetch(rel_href, {method: 'PATCH', json: {data: null}});
              onChanged && onChanged();
          }
        : null;
    const handleAdd = editable
        ? async () => {
              const data = await subSelectDialog();
              if (data) {
                  await api.fetch(rel_href, {method: 'PATCH', json: {data}});
                  onChanged && onChanged();
              }
          }
        : null;
    return (
        <>
            {sub ? (
                <Chip
                    label={sub.attributes.userinfo.email}
                    onDelete={handleDelete}
                />
            ) : (
                editable && (
                    <IconButton onClick={handleAdd}>
                        <Add />
                    </IconButton>
                )
            )}
        </>
    );
}
