import React, {useEffect, useMemo} from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {parseISO} from 'date-fns';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'it/routes';
import useResources from 'it/dataSource/resources';
import ChargeDetailTable from 'it/components/ChargeDetailTable';
import BasePage from './BasePage';

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.captured_at', direction: 'asc'},
    page: {number: 0, size: 20},
};

function useChargeDetails(props) {
    return useResources({
        link: 'charge.ChargeCollection',
        prefix: 'charge.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

function useResource({type, id, href}) {
    const api = useApi();
    const selector = useMemo(
        () => ducks.jsonapi.selectObject([type, id]),
        [type, id],
    );
    const result = useSelector(selector);
    useEffect(() => {
        if (result) return;
        api.fetchJson(href);
    }, [api, result, href]);
    return result;
}

export default function ChargeDetailPage() {
    const api = useApi();
    const {chargeCaptureId, providerId} = useParams();
    const chargeCapture = useResource({
        type: 'ChargeCapture',
        id: chargeCaptureId,
        href: api.url_for('charge.ChargeCapture', {
            charge_capture_id: chargeCaptureId,
        }),
    });
    const provider = useResource({
        type: 'Provider',
        id: providerId,
        href: api.url_for('provider.Provider', {
            provider_id: providerId,
        }),
    });
    const mergeFetchOptions = useMemo(() => {
        if (!provider || !chargeCapture) return null;
        const providerCodes = fp.get('attributes.amd_profile_codes', provider);
        const start = fp.pipe([
            fp.get('attributes.start_date_inclusive'),
            dt => parseISO(dt).getTime(),
        ])(chargeCapture);
        const end = fp.pipe([
            fp.get('attributes.end_date_exclusive'),
            dt => parseISO(dt).getTime(),
        ])(chargeCapture);
        return {
            filter: {
                'attributes.provider_code': {$in: providerCodes},
                'attributes.charge_created_at': {
                    $gte: {$date: start},
                    $lt: {$date: end},
                },
            },
        };
    }, [provider, chargeCapture]);
    const ds = useChargeDetails({mergeFetchOptions});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => ds.fetch(), [mergeFetchOptions]);
    const chargeCaptureHref = url_for('providerRvuSummaries', {
        chargeCaptureId,
    });
    const title = (
        <span>
            Charges recorded for {fp.get('attributes.name', provider)} in{' '}
            <R.Link to={chargeCaptureHref}>
                {fp.get('attributes.title', chargeCapture)}
            </R.Link>
        </span>
    );
    if (!mergeFetchOptions) return null;
    return (
        <BasePage title='Provider Charge Detail'>
            <ChargeDetailTable
                title={title}
                dataSource={ds}
            ></ChargeDetailTable>
        </BasePage>
    );
}
