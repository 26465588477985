import React from 'react';
import {AppBar as MUIAppBar, Toolbar, IconButton} from '@material-ui/core';
import {Menu} from '@material-ui/icons';

import AuthInfo from 'it/components/AuthInfo';

export default function AppBar({title, subtitle, onToggleDrawer}) {
    return (
        <MUIAppBar position='static' color='inherit'>
            <Toolbar>
                <IconButton
                    edge='start'
                    onClick={onToggleDrawer}
                    color='inherit'
                >
                    <Menu />
                </IconButton>
                <h6>
                    {title} {subtitle && ` | ${subtitle}`}
                </h6>
                <div style={{flex: 1}} />
                <AuthInfo />
            </Toolbar>
        </MUIAppBar>
    );
}
