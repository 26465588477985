import React from 'react';
import fp from 'lodash/fp';

import UriTemplate from 'uritemplate';

import {useApi} from '@arborian/narrf';

import ChipList from 'it/components/ChipList';

export default function TeamFacilityNames({team, editable, onChanged}) {
    const api = useApi();
    const codeUriTemplate = UriTemplate.parse(fp.get('links.facility', team));
    const handleDelete = async (ev, value) => {
        console.log('Remove facility from team', {team, value});
        const href = codeUriTemplate.expand({name: value});
        await api.fetch(href, {method: 'DELETE'});
        onChanged && onChanged();
    };
    return (
        <ChipList
            items={fp.get('attributes.facility_names', team)}
            onDelete={editable ? handleDelete : null}
        />
    );
}
