import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@material-ui/core';
import {Autocomplete} from 'formik-material-ui-lab';
import {Formik, Form, Field} from 'formik';

import {ducks} from '@arborian/narrf';

const selectSubs = ducks.jsonapi.selectObject('Sub');

function SubSelect({label, name, touched, errors, ...props}) {
    const options = fp.values(useSelector(selectSubs));
    console.log('All options is', options);
    console.log('SubSelect', props);
    return (
        <Autocomplete
            options={options}
            getOptionLabel={fp.get('attributes.userinfo.email')}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    autoFocus
                    errors={touched && touched[name] && !!errors[name]}
                    helperText={errors && errors[name]}
                />
            )}
            {...props}
        />
    );
}
export default function SubSelectDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState();

    const handleSubmit = values => {
        resolve(values.value);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = provider => {
        return new Promise((resolve, reject) => {
            setInitialValues({value: null});
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Select User</DialogTitle>
                        <DialogContent>
                            <p>Select a user to link to this provider</p>
                            <Field
                                name='value'
                                label='User email'
                                component={SubSelect}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Select
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
