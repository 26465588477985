import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core';

import useGlobalStyles from 'it/globalStyles';
import NavDrawer from 'it/components/NavDrawer';
import AppBar from 'it/components/AppBar';

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(4),
    },
}));

export default function BasePage({title, subtitle, children, classes = {}}) {
    classes = {...useGlobalStyles(), ...useStyles(), ...classes};
    const [drawerOpen, setDrawerOpen] = useState(false);
    return (
        <div className={classes.root}>
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <AppBar
                title={title}
                subtitle={subtitle}
                onToggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />
            <div className={classes.content}>{children}</div>
        </div>
    );
}
