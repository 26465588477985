import React from 'react';
import fp from 'lodash/fp';

import {Avatar, Button, makeStyles} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    button: {
        width: 244,
        height: 40,
        marginTop: 6,
        marginBottom: 6,
        marginRight: 4,
    },
    avatar: {
        width: 16,
        height: 16,
    },
}));

export default function LoginButton({
    provider,
    children,
    intent,
    state,
    image,
    ...props
}) {
    const api = useApi();
    const classes = useStyles();

    if (!provider) return null;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri,
        provider_id: provider.id,
        intent,
        state,
    });
    if (!image) {
        image = fp.get('attributes.image_url', provider);
    }
    const avatar = (
        <Avatar
            classes={{root: classes.avatar}}
            src={image}
            icon={<I.Face />}
        />
    );
    return (
        <Button
            classes={{root: classes.button}}
            href={authorizeLink}
            variant='outlined'
            startIcon={avatar}
            {...props}
        >
            {children}
        </Button>
    );
}
