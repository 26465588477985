import React, {useCallback, useEffect, useState} from 'react';
import fp from 'lodash/fp';

import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

export default function RVUHistogram({chargeCapture, type}) {
    const api = useApi();
    const [svgData, setSvgData] = useState('');
    const [open, setOpen] = useState(false);

    const fetchGraph = useCallback(
        async chargeCapture => {
            const link = `histogram_by_${type}`;
            const resp = await api.fetch(
                fp.get(['links', link], chargeCapture),
            );
            setSvgData(await resp.text());
        },
        [api, type, setSvgData],
    );
    useEffect(() => {
        if (chargeCapture) {
            fetchGraph(chargeCapture);
        }
    }, [chargeCapture, fetchGraph]);

    const handleChangeAccordion = (ev, open) => {
        setOpen(open);
    };

    return (
        <div>
            <Accordion expanded={open} onChange={handleChangeAccordion}>
                <AccordionSummary>
                    <h6>RVU Distribution</h6>
                </AccordionSummary>
                <AccordionDetails>
                    <div dangerouslySetInnerHTML={{__html: svgData}} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
