import React from 'react';
import fp from 'lodash/fp';

import UriTemplate from 'uritemplate';

import {useApi} from '@arborian/narrf';

import ChipList from 'it/components/ChipList';

export default function ProviderProfileCodes({provider, editable, onChanged}) {
    const api = useApi();
    const codeUriTemplate = UriTemplate.parse(fp.get('links.code', provider));
    const handleDelete = async (ev, value) => {
        console.log('Remove code from provider', {provider, value});
        const href = codeUriTemplate.expand({code: value});
        await api.fetch(href, {method: 'DELETE'});
        onChanged && onChanged();
    };
    return (
        <ChipList
            items={fp.get('attributes.amd_profile_codes', provider)}
            onDelete={editable ? handleDelete : null}
        />
    );
}
