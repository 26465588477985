import * as h from 'it/lib/helpers';

const TZ = 'US/Eastern';

export function DateTime({value}) {
    return <span>{h.formatDateTime(value, TZ)}</span>;
}

export function DateOnly({value}) {
    return <span>{h.formatDateOnly(value, TZ)}</span>;
}
