import React from 'react';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';

import {Button} from '@material-ui/core';
import {components} from '@arborian/narrf';

import {url_for} from 'it/routes';
import useResources from 'it/dataSource/resources';
import {DateTime, DateOnly} from 'it/components/Date';

import BasePage from './BasePage';

const {dt2} = components;

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.title', direction: 'desc'},
    page: {number: 0, size: 20},
};

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
};

function useChargeCapture(props) {
    return useResources({
        link: 'charge.ChargeCaptureCollection',
        prefix: 'capture.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

function TitleValue({item}) {
    return (
        <>
            {item.attributes.title}
            &nbsp;
            <Button
                variant='outlined'
                component={Link}
                to={url_for('providerRvuSummaries', {chargeCaptureId: item.id})}
            >
                by provider
            </Button>
            &nbsp;
            <Button
                variant='outlined'
                component={Link}
                to={url_for('teamRvuSummaries', {chargeCaptureId: item.id})}
            >
                by team
            </Button>
        </>
    );
}

export default function ChargeCapturePage() {
    const ds = useChargeCapture();
    const handleRefresh = ev => {
        ds.fetch();
    };
    return (
        <BasePage title='Charge Captures'>
            <dt2.DataTable
                small
                title='Full Charge Capture Listing'
                dataSource={ds}
                options={TABLE_OPTIONS}
            >
                <dt2.Column
                    title='Last Updated'
                    type='date'
                    field='attributes.captured_at'
                    render={row => (
                        <DateTime
                            value={fp.get('data.attributes.captured_at', row)}
                        />
                    )}
                />
                <dt2.Column
                    title='Title'
                    field='attributes.title'
                    render={item => <TitleValue item={item.data} />}
                />
                <dt2.Column
                    title='Start'
                    field='attributes.start_date_inclusive'
                    type='date'
                    render={row => (
                        <DateOnly
                            value={fp.get(
                                'data.attributes.start_date_inclusive',
                                row,
                            )}
                        />
                    )}
                />
                <dt2.Column
                    title='End'
                    field='attributes.end_date_exclusive'
                    type='date'
                    render={row => (
                        <DateOnly
                            value={fp.get(
                                'data.attributes.end_date_exclusive',
                                row,
                            )}
                        />
                    )}
                />
                <dt2.Column title='Notes' field='attributes.notes' />
                <dt2.Action
                    free
                    id='charge-refresh'
                    onClick={handleRefresh}
                    icon='refresh'
                />
            </dt2.DataTable>
        </BasePage>
    );
}
