import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useApi} from '@arborian/narrf';

import {url_for} from 'it/routes';
import BasePage from './BasePage';

export default function LogoutPage() {
    const [init, setInit] = useState();
    const api = useApi();
    const history = useHistory();

    useEffect(() => {
        if (!init) {
            setInit(true);
            api.logout(true)
                .then(x => {
                    history.push(url_for('home'));
                })
                .catch(e => {
                    console.log('Error logging out', e);
                    history.push(url_for('home'));
                });
        }
    }, [api, history, init]);
    return <BasePage title='Logging you out...' />;
}
