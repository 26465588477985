import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import {ApiRoute} from '@arborian/narrf';
import routes from 'it/routes';

import HomePage from 'it/pages/HomePage';

import LoginPage from 'it/pages/LoginPage';
import LogoutPage from 'it/pages/LogoutPage';
import CallbackPage from 'it/pages/CallbackPage';

import ChargeCapturePage from 'it/pages/ChargeCapturePage';
import ProviderRVUSummaryPage from 'it/pages/ProviderRVUSummaryPage';
import ProviderChargeDetailPage from 'it/pages/ProviderChargeDetailPage';
import TeamRVUSummaryPage from 'it/pages/TeamRVUSummaryPage';
import TeamChargeDetailPage from 'it/pages/TeamChargeDetailPage';

import CodePage from 'it/pages/CodePage';
import ProviderPage from 'it/pages/ProviderPage';
import TeamPage from 'it/pages/TeamPage';

export default function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <ApiRoute exact path={routes.home.path} component={HomePage} />
                {/* Auth pages */}
                <ApiRoute
                    exact
                    path={routes.login.path}
                    component={LoginPage}
                />
                <ApiRoute
                    exact
                    path={routes.logout.path}
                    component={LogoutPage}
                />
                <ApiRoute
                    exact
                    path={routes.callback.path}
                    component={CallbackPage}
                />
                {/* Charge table pages */}
                <ApiRoute
                    exact
                    path={routes.chargeCapture.path}
                    component={ChargeCapturePage}
                />
                <ApiRoute
                    exact
                    path={routes.providerRvuSummaries.path}
                    component={ProviderRVUSummaryPage}
                />
                <ApiRoute
                    exact
                    path={routes.providerChargeDetail.path}
                    component={ProviderChargeDetailPage}
                />
                <ApiRoute
                    exact
                    path={routes.teamRvuSummaries.path}
                    component={TeamRVUSummaryPage}
                />
                <ApiRoute
                    exact
                    path={routes.teamChargeDetail.path}
                    component={TeamChargeDetailPage}
                />
                {/* Admin pages */}
                <ApiRoute exact path={routes.code.path} component={CodePage} />
                <ApiRoute
                    exact
                    path={routes.provider.path}
                    component={ProviderPage}
                />
                <ApiRoute exact path={routes.team.path} component={TeamPage} />
            </Switch>
        </BrowserRouter>
    );
}
