import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {reverse} from 'named-urls';

const routeList = [
    {name: 'home', path: '/'},
    {name: 'login', path: '/login'},
    {name: 'logout', path: '/logout'},
    {name: 'callback', path: '/callback'},
    {name: 'chargeCapture', path: '/charge-captures'},
    {
        name: 'providerRvuSummaries',
        path: '/charge-captures/:chargeCaptureId/provider-rvu',
    },
    {
        name: 'teamRvuSummaries',
        path: '/charge-captures/:chargeCaptureId/team-rvu',
    },
    {
        name: 'providerChargeDetail',
        path: '/charge-captures/:chargeCaptureId/provider/:providerId',
    },
    {
        name: 'teamChargeDetail',
        path: '/charge-captures/:chargeCaptureId/team/:teamId',
    },
    {name: 'provider', path: '/provider'},
    {name: 'team', path: '/team'},
    {name: 'code', path: '/code'},
];

export const routes = fp.transform(
    (acc, r) => {
        acc[r.name] = r;
    },
    {},
    routeList,
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default routes;
