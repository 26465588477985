import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';

import {makeStyles, Button} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'it/routes';

const useStyles = makeStyles(theme => ({
    authLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    userLabel: {
        padding: 6,
        marginTop: 4,
    },
}));

export default function AuthInfo() {
    const classes = useStyles();

    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    let userLabel = 'Anonymous';
    if (userinfo) {
        userLabel = userinfo.name || userinfo.username || userinfo.email;
    }

    if (!api.isAuthorized()) {
        return (
            <>
                <Button
                    id='login-link'
                    color='inherit'
                    component={R.Link}
                    to={url_for('login')}
                >
                    Sign in
                </Button>
            </>
        );
    }
    return (
        <div className={classes.authLabel}>
            <AccountCircle />
            {userLabel}
            <Button color='inherit' component={R.Link} to={url_for('logout')}>
                Sign out
            </Button>
        </div>
    );
}
