import React from 'react';
import fp from 'lodash/fp';

import {Chip as MUIChip} from '@material-ui/core';

function Chip({value, onDelete}) {
    const handleDelete = onDelete ? ev => onDelete(ev, value) : null;
    return <MUIChip label={value} onDelete={handleDelete} />;
}

export default function ChipList({items, onDelete}) {
    if (fp.isEmpty(items)) return null;
    return (
        <div>
            {fp.map(
                item => (
                    <Chip key={item} value={item} onDelete={onDelete} />
                ),
                items,
            )}
        </div>
    );
}
