import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {DateOnly} from 'it/components/Date';

const {dt2} = components;

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
};

const VOID_LOOKUP = {
    true: 'VOIDED',
    false: '',
};

const SUPP_LOOKUP = {
    true: 'SUPPRESSED',
    false: '',
};

export default function ChargeDetailTable({title, dataSource, children}) {
    const handleRefresh = ev => {
        dataSource.fetch();
    };
    return (
        <dt2.DataTable
            small
            title={title}
            dataSource={dataSource}
            options={TABLE_OPTIONS}
        >
            <dt2.Column
                title='Created'
                type='date'
                field='attributes.charge_created_at'
                render={row => (
                    <DateOnly
                        value={fp.get('data.attributes.charge_created_at', row)}
                    />
                )}
            />
            <dt2.Column
                title='Encounter Date'
                type='date'
                field='attributes.appointment_start'
                render={row => (
                    <DateOnly
                        value={fp.get('data.attributes.appointment_start', row)}
                    />
                )}
            />
            <dt2.Column title='Profile' field='attributes.provider_code' />
            {children}
            <dt2.Column title='Facility' field='attributes.facility_name' />
            <dt2.Column title='Last' field='attributes.patient_last_name' />
            <dt2.Column title='First' field='attributes.patient_first_name' />
            <dt2.Column
                title='DOB'
                type='date'
                field='attributes.patient_dob'
                render={row => (
                    <DateOnly
                        value={fp.get('data.attributes.patient_dob', row)}
                    />
                )}
            />
            <dt2.Column title='CPT' field='attributes.charge_code_code' />
            <dt2.Column
                title='Desc'
                field='attributes.charge_code_description'
            />
            <dt2.Column title='RVU' field='attributes.charge_code_rvu' />
            <dt2.Column
                title='Void'
                field='attributes.charge_void'
                type='bool'
                lookup={VOID_LOOKUP}
            />
            <dt2.Column
                title='Suppressed'
                field='attributes.charge_code_suppressed'
                type='bool'
                lookup={SUPP_LOOKUP}
            />
            <dt2.Action
                free
                id='charge-refresh'
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
