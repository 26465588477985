import React, {useState} from 'react';
import fp from 'lodash/fp';
import {parseISO} from 'date-fns';

import {IconButton} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {Check, Close, Edit} from '@material-ui/icons';

import * as h from 'it/lib/helpers';
import {useResourceActions} from 'it/actions';

export default function TextField({
    resource,
    field,
    onChanged,
    iconBefore = false,
}) {
    const actions = useResourceActions(resource);
    const [_value, set_value] = useState(null);
    const [editing, setEditing] = useState(false);
    const canEdit = fp.pipe([
        fp.get('meta.allowed_actions'),
        fp.includes('Common.WRITE'),
    ])(resource);
    const value = fp.get(field, resource);

    const handleEdit = ev => {
        const date = parseISO(value);
        const string = h.formatDateOnly(date);
        set_value({date, string});
        setEditing(true);
    };
    const handleChange = (newDateValue, newStringValue) => {
        set_value({date: newDateValue, string: newStringValue});
    };
    const handleSave = async ev => {
        await actions.putField(field, _value.date.toISOString());
        setEditing(false);
        onChanged && onChanged();
    };
    const handleCancel = ev => setEditing(false);

    return editing ? (
        <span>
            <KeyboardDatePicker
                disableToolbar
                //fullWidth
                variant='inline'
                format='MM/dd/yyyy'
                margin='normal'
                // id={fieldId('dyn', field.name)}
                // label={label}
                value={_value.date}
                inputValue={_value.string}
                // required={required}
                // onError={setError}
                onChange={handleChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                /*
                error={(error || touched) && fp.get(field.name, form.errors)}
                helperText={
                    (error || touched) && fp.get(field.name, form.errors)
                }
                 */
            />
            <IconButton onClick={handleSave} size='small'>
                <Check />
            </IconButton>
            <IconButton onClick={handleCancel} size='small'>
                <Close />
            </IconButton>
        </span>
    ) : (
        <span>
            {!iconBefore && h.formatDateOnly(value)}
            {canEdit && (
                <IconButton onClick={handleEdit} size='small'>
                    <Edit />
                </IconButton>
            )}
            {iconBefore && h.formatDateOnly(value)}
        </span>
    );
}
